/* CreateLeagueForm.css */

.create-league-container {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #282c34;
    border-radius: 8px;
    background-color: #1a1a2e;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    color: #d1d1d1;
}

.create-league-container h2 {
    text-align: center;
    color: #d1d1d1;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    font-weight: bold;
    color: #b8b7ad;
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea,
.form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: #282c34;
    color: #d1d1d1;
    box-sizing: border-box;
}

.form-group textarea {
    resize: vertical;
    min-height: 100px;
}

.form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.create-button,
.cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.create-button {
    background-color: #4CAF50;
    color: white;
}

.create-button:hover {
    background-color: #45a049;
}

.cancel-button {
    background-color: #e74c3c;
    color: white;
}

.cancel-button:hover {
    background-color: #c0392b;
}

.error-message {
    color: #ff4d4d;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
}

.success-message {
    color: #4CAF50;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
}