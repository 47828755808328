.create-bet-form {
    max-width: 99%;
    margin: 0.1em;
    padding: 0.1em;
    background-color: #1a1a2e;
    color: #ffffff;
    border-radius: 0.625em;
    box-shadow: 0 0 0.625em rgba(0, 0, 0, 0.3);
}

h1 {
    color: #ffffff;
    text-align: center;
    margin-bottom: 1em;
    font-size: 1.5em;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.form-group {
    margin-bottom: 1.25em;
    position: relative;
}

label {
    display: block;
    margin-bottom: 0.3125em;
    color: #4CAF50;
    font-size: 1em;
    font-weight: bold;
}

input[type="text"],
input[type="number"],
input[type="datetime-local"],
input[type="date"],
input[type="time"],
textarea,
select {
    width: 100%;
    padding: 0.75em;
    border: 0.0625em solid #4CAF50;
    border-radius: 0.25em;
    background-color: #23233f;
    color: #ffffff;
    font-size: 1em;
}

input::placeholder,
textarea::placeholder {
    color: #eaf1f7;
}

textarea {
    height: 6.25em;
    resize: vertical;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='%23ffffff' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 50%;
    padding-right: 2em;
}

/* Fixed size for bet type select */
select[name="betType"],
select[name="deadlineType"] {
    height: 3.125em;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
    border-color: #45a049;
    box-shadow: 0 0 0 0.125em rgba(69, 160, 73, 0.2);
}

.deadline-box {
    border: 0.0625em solid #4CAF50;
    border-radius: 0.25em;
    padding: 0.9375em;
    margin-bottom: 1.25em;
    background-color: #23233f;
}

.deadline-box legend {
    color: #4CAF50;
    padding: 0 0.625em;
    font-weight: bold;
}

.form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25em;
}

.btn-primary,
.btn-secondary {
    padding: 0.75em 1.25em;
    border: none;
    border-radius: 2em;
    cursor: pointer;
    font-size: 1em;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.btn-primary {
    background-color: #4CAF50;
    color: white;
}

.btn-primary:hover {
    background-color: #45a049;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.btn-secondary {
    background-color: #008CBA;
    color: white;
}

.btn-secondary:hover {
    background-color: #007399;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.error-message {
    background-color: #f44336;
    color: white;
    padding: 0.625em;
    border-radius: 0.25em;
    margin-bottom: 1.25em;
    text-align: center;
}

.loading {
    text-align: center;
    color: #4CAF50;
    font-size: 1.125em;
    margin-top: 1.25em;
}

.select-timezone-btn {
    width: 100%;
    padding: 0.75em;
    background-color: #23233f;
    color: #ffffff;
    border: 0.0625em solid #4CAF50;
    border-radius: 0.25em;
    font-size: 1em;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.select-timezone-btn:hover {
    background-color: #2c2c4e;
}

select {
    max-height: 12.5em;
    overflow-y: auto;
}

select::-webkit-scrollbar {
    width: 0.5em;
}

select::-webkit-scrollbar-track {
    background: #23233f;
}

select::-webkit-scrollbar-thumb {
    background-color: #4CAF50;
    border-radius: 0.25em;
}

.custom-select {
    position: relative;
}

.custom-select-options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    max-height: 50vh;
    overflow-y: auto;
    background-color: #23233f;
    border: 1px solid #4CAF50;
    border-top: none;
    border-radius: 0 0 0.25em 0.25em;
}

.custom-select-option {
    padding: 0.75em;
    cursor: pointer;
}

.custom-select-option:hover {
    background-color: #2c2c4e;
}

.timezone-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.timezone-modal-content {
    background-color: #1a1a2e;
    padding: 1em;
    border-radius: 0.5em;
    max-width: 90%;
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.2);
}

.timezone-list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 60vh;
    overflow-y: auto;
}

.timezone-list li {
    padding: 0.5em;
    cursor: pointer;
    transition: background-color 0.2s ease;
    color: #ffffff;
}

.timezone-list li:hover {
    background-color: #4CAF50;
    color: white;
}

.close-modal-btn {
    display: block;
    width: 100%;
    padding: 0.75em;
    margin-top: 1em;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 2em;
    font-size: 1em;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.close-modal-btn:hover {
    background-color: #d32f2f;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

@media (max-width: 768px) {
    .create-bet-form {
        padding: 1em;
    }

    .form-actions {
        flex-direction: column;
    }

    .btn-primary,
    .btn-secondary {
        width: 100%;
        margin-bottom: 0.625em;
    }

    select {
        font-size: 1em;
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
    }

    .form-group {
        overflow: visible;
        position: relative;
        width: 100%;
    }

    select {
        position: relative;
        z-index: 1000;
        max-height: 50vh;
    }

    .timezone-modal-content {
        max-height: 90vh;
    }

    .timezone-list {
        max-height: 70vh;
    }
}

@media (min-width: 769px) {
    select {
        max-height: 18.75em;
    }
}