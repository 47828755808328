.validation-popup-container, .confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
  }
  
  .confirmation-overlay {
    z-index: 1001; /* Ensure it's on top of the validation popup */
  }
  
  .validation-popup, .confirmation-modal {
    background-color: #1a1a2e;
    border-radius: 10px;
    padding: 20px;
    width: 90%;
    max-width: 600px; 
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }
  
  .validation-popup {
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .validation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #2c2c4e;
  }
  
  .validation-header h2 {
    color: #4CAF50;
    margin: 0;
    font-size: 1.5em;
  }
  
  .progress-indicator {
    color: #b8b7ad;
    font-size: 0.9em;
  }
  
  .bet-details {
    margin-bottom: 20px;
  }
  
  .bet-info h3 {
    color: #d1d1d1;
    margin-bottom: 15px;
  }
  
  .bet-info p {
    color: #b8b7ad;
    margin: 10px 0;
    line-height: 1.4;
  }
  
  .bet-info strong {
    color: #d1d1d1;
    margin-right: 10px;
  }
  
  .validation-actions, .confirmation-buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px; 
  }
  
  .validation-actions {
    justify-content: space-around; 
  }
  
  .validation-actions button, .confirm-button, .cancel-button {
    flex: 1;
    padding: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: all 0.3s ease;
  }
  
  .validate-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .validate-button:hover {
    background-color: #45a049;
  }
  
  .challenge-button {
    background-color: #e74c3c;
    color: white;
  }
  
  .challenge-button:hover {
    background-color: #c0392b;
  }
  
  .navigation-actions {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .skip-button,
  .end-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #2c2c4e;
    color: #d1d1d1;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.3s ease;
  }
  
  .skip-button:hover,
  .end-button:hover {
    background-color: #373a47;
  }
  
  .confirmation-content h3 {
    color: #4CAF50;
    margin-top: 0;
  }
  
  .confirmation-content p {
    color: #d1d1d1;
    margin-bottom: 20px;
  }
  
  
  .confirm-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .confirm-button:hover {
    background-color: #45a049;
  }
  
  .cancel-button {
    background-color: #e74c3c;
    color: white;
  }
  
  .cancel-button:hover {
    background-color: #c0392b;
  }
  
  @media (max-width: 600px) {
    .validation-popup, .confirmation-modal {
      width: 95%;
      padding: 15px;
    }
  
    .validation-actions, .confirmation-buttons {
      flex-direction: column;
      align-items: center; 
    }
  
    .validation-actions button, .confirm-button, .cancel-button {
      width: 100%; 
      margin-bottom: 10px; 
    }
  }