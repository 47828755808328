.app-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #1a1a2e;
    padding: 1.8vh 0;
    box-shadow: 0 -0.2vh 1vh rgba(0, 0, 0, 0.1);
    height: 9.6vh; /* Increased from 8vh */
    z-index: 1000;
}

.footer-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: #8a8a8a;
    font-size: 3vh; /* Increased from 2.5vh */
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    width: 33.33%;
    height: 100%;
    outline: none;
}

.footer-button::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0.36vh; /* Increased from 0.3vh */
    background-color: #4CAF50;
    transition: width 0.3s ease;
}

.footer-button:hover,
.footer-button:focus {
    color: #ffffff;
}

.footer-button.active {
    color: #4CAF50;
}

.footer-button.active::after {
    width: 60%; /* Increased from 50% */
}

.button-text {
    font-size: 1.8vh; /* Increased from 1.5vh */
    margin-top: 0.6vh;
    font-weight: 500;
}

.notification-dot {
    position: absolute;
    top: 0;
    right: 25%;
    background-color: #ff4d4d;
    color: white;
    border-radius: 50%;
    padding: 0.36vh 0.96vh; /* Increased from 0.3vh 0.8vh */
    font-size: 1.56vh; /* Increased from 1.3vh */
    font-weight: bold;
}

@media (max-width: 600px) {
    .app-footer {
        height: 8.4vh; /* Increased from 7vh */
    }

    .footer-button {
        font-size: 3.6vh; /* Increased from 3vh */
    }

    .button-text {
        display: none;
    }

    .notification-dot {
        top: -0.96vh; /* Adjusted from -0.8vh */
        right: 20%;
        padding: 0.24vh 0.72vh; /* Increased from 0.2vh 0.6vh */
        font-size: 1.44vh; /* Increased from 1.2vh */
    }
}