/* Reset and base styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body, html {
    height: 100%;
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    color: #333;
}

/* Container styles */
.sign-up-container {
    width: 100%;
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    background-color: #333;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Form styles */
.sign-up-form {
    width: 100%;
}

h1 {
    color: #4CAF50;
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    color: #fff;
    font-size: 14px;
}

input, select {
    width: 100%;
    padding: 12px;
    border: 1px solid #555;
    border-radius: 4px;
    background-color: #444;
    color: #fff;
    font-size: 16px;
}

input::placeholder {
    color: #aaa;
}

input[type="date"] {
    appearance: none;
    -webkit-appearance: none;
    color: #aaa;
    font-family: Arial, sans-serif;
    font-size: 16px;
    border: 1px solid #555;
    background: #444;
    padding: 12px;
}

/* Button styles */
.sign-up-button {
    width: 100%;
    padding: 12px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sign-up-button:hover {
    background-color: #45a049;
}

/* Message styles */
.error-message, .success-message {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
}

.error-message {
    background-color: #ff4d4d;
    color: white;
}

.success-message {
    background-color: #4CAF50;
    color: white;
}

/* Responsive adjustments */
@media (max-width: 480px) {
    .sign-up-container {
        margin: 10px auto;
        padding: 15px;
    }

    h1 {
        font-size: 22px;
    }

    input, select, .sign-up-button {
        font-size: 14px;
    }
}

/* Ensure inputs don't zoom on focus in iOS */
@media screen and (-webkit-min-device-pixel-ratio:0) { 
    select,
    textarea,
    input {
        font-size: 16px;
    }
}

/* DatePicker styles */
.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container input {
    width: 100%;
    padding: 12px;
    border: 1px solid #555;
    border-radius: 4px;
    background-color: #444;
    color: #fff;
    font-size: 16px;
}

@media (max-width: 480px) {
    .react-datepicker__input-container input {
        font-size: 14px;
    }
}

/* Timezone Select styles */
.timezone-select {
    color: #fff;
}

.timezone-select .react-select__control {
    background-color: #444;
    border-color: #555;
}

.timezone-select .react-select__single-value {
    color: #fff;
}

.timezone-select .react-select__menu {
    background-color: #444;
}

.timezone-select .react-select__option {
    background-color: #444;
    color: #fff;
}

.timezone-select .react-select__option--is-focused {
    background-color: #555;
}

.timezone-select .react-select__option--is-selected {
    background-color: #4CAF50;
}

.timezone-select .react-select__input-container {
    color: #fff;
}

.timezone-select .react-select__indicator-separator {
    background-color: #555;
}

.timezone-select .react-select__indicator {
    color: #fff;
}

.form-group {
    width: 100%;
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group .react-datepicker-wrapper,
  .timezone-selector-container {
    width: 100%;
  }
  
  .timezone-selector-container .timezone-selector {
    width: 100%;
  }
  
  .timezone-selector-container .react-select__control {
    min-height: 38px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .timezone-selector-container .react-select__value-container {
    height: 38px;
  }
  
  .timezone-selector-container .react-select__input {
    height: 38px;
  }
  
  .timezone-selector-container .react-select__menu {
    width: 100%;
  }
  
  /* Ensure consistent sizing for date picker input */
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    display: block;
    width: 100%;
  }
  
  /* Adjust button sizing */
  .sign-up-button {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    font-size: 16px;
  }