.confirm-signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #1a1a2e;
}

.confirm-signup-form {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.form-group {
    margin-bottom: 15px;
}

.confirm-button,
.resend-button {
    padding: 10px;
    margin-top: 10px;
}

.error-message {
    color: red;
    margin-bottom: 10px;
}

.message {
    color: green;
    margin-bottom: 10px;
}
