.profile-container {
    max-width: 99%;
    margin: 0.1em;
    padding: 0.1em;
    background-color: #1a1a2e; /* Primary background color */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Adjusted shadow for dark theme */
    color: #d1d1d1; /* Primary text color */
    z-index: 1;
}

.profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.profile-header-buttons {
    display: flex;
    gap: 10px;
}

.icon-button {
    width: 40px;
    height: 40px;
    padding: 8px;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.icon-button svg {
    width: 24px;
    height: 24px;
    fill: #d1d1d1; /* Primary text color */
}

.back-button {
    order: -1; /* Ensures the back button is on the left */
}

.refresh-button {
    order: 1; /* Ensures the refresh button is on the right */
}

.profile-header h1 {
    margin: 0;
    color: #d1d1d1; /* Primary text color */
}

.profile-section {
    margin-bottom: 20px;
}

.profile-section h2 {
    margin-bottom: 10px;
    color: #d1d1d1; /* Primary text color */
}

.profile-section p {
    margin: 0;
    padding: 10px;
    background-color: #282c34; /* Secondary background color */
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Adjusted shadow for dark theme */
    color: #d1d1d1; /* Primary text color */
}

.profile-section label {
    display: block;
    margin-bottom: 5px;
    color: #b8b7ad; /* Secondary text color */
}

.profile-section select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc; /* Light gray border */
    background-color: #282c34; /* Secondary background color */
    color: #d1d1d1; /* Primary text color */
    z-index: 1;
}

.save-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #4CAF50; /* Primary button background color */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.save-button:hover {
    background-color: #45a049; /* Button hover color */
}

.back-button,
.refresh-button {
    padding: 10px 15px;
    background-color: #4CAF50; /* Primary button background color */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.back-button:hover,
.refresh-button:hover {
    background-color: #45a049; /* Button hover color */
}

.dashboard-buttons {
    flex-direction: column;
    gap: 10px;
}

.logout-button {
    background-color: #f44336; /* Red color for logout */
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.logout-button:hover {
    background-color: #d32f2f; /* Darker red on hover */
}
.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

.loading-container p {
    margin-top: 20px;
    font-size: 18px;
    color: #333;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.push-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-top: 10px;
}

.push-button:hover {
    background-color: #45a049;
}