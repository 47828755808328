/* Header styling */
.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    background-color: #1a1a2e;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1000;
}

/* Logo container */
.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.logo {
    height: 40px;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.logo:hover {
    opacity: 0.8;
}

/* Burger menu button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 20px;
    top: 15px;
}

/* Burger menu styling */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

.bm-menu {
    background: #282c34;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Menu item styling */
.menu-item {
    color: #d1d1d1;
    padding: 10px;
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-size: 1em;
}

.menu-item:hover {
    background-color: #45a049;
    color: white;
}

/* Error message styling */
.error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
}

/* Refresh button styling */
.refresh-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.refresh-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.refresh-button svg {
    width: 24px;
    height: 24px;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .header {
        height: 50px;
    }

    .bm-burger-button {
        width: 30px;
        height: 24px;
        left: 10px;
        top: 13px;
    }

    .logo {
        height: 30px;
    }

    .refresh-button {
        width: 50px;
        height: 50px;
    }

    .refresh-button svg {
        width: 20px;
        height: 20px;
    }
}