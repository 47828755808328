body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #e8f0fe; /* Light blue */
  font-family: Arial, sans-serif;
}

#outer-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px; /* Adjust the height as needed */
  background-color: #f8f9fa; /* Adjust the background color as needed */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
}

#page-wrap {
  flex: 1;
  margin-top: 60px; /* Adjust this to match the header height */
  padding: 20px;
  overflow: auto;
  background-color: #e9ecef; /* Adjust the background color as needed */
}

/* Center the login container */
.login-container {
  max-width: 400px; /* Fixed width for better centering */
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f4f4f9; /* Light background color for better contrast */
  text-align: center;
}

/* Style the form elements */
.login-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center; /* Center form elements */
}

.form-group {
  width: 100%; /* Full width for form group */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center form group */
}

.form-group label {
  align-self: flex-start; /* Align labels to the start */
  margin-bottom: 5px;
  font-weight: bold;
  color: #2c3e50; /* Darker text for labels */
}

.form-group input {
  width: 100%;
  max-width: 350px; /* Set max-width for input fields */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #e8f0fe; /* Light blue background for inputs */
}

/* Style the buttons */
button, .login-button, .sign-up-button, .forgot-password-button {
  width: 100%; /* Full width for buttons */
  max-width: 350px; /* Set max-width for buttons */
  padding: 10px;
  font-size: 16px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button {
  background-color: #1f618d; /* Blue marine */
}

.login-button:hover {
  background-color: #154360;
}

.sign-up-button {
  background-color: #1f618d; /* Blue */
}

.sign-up-button:hover {
  background-color: #154360;
}

.forgot-password-button {
  background-color: #1f618d; /* Blue */
}

.forgot-password-button:hover {
  background-color: #154360;
}

/* Error message styling */
.error-message {
  color: #e74c3c;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Media Query for smaller screens */
@media (max-width: 600px) {
  .login-container {
      max-width: 90%; /* Reduce width for small screens */
      padding: 15px;
      box-shadow: none;
  }

  .form-group input {
      padding: 8px;
  }

  .login-button, .sign-up-button, .forgot-password-button {
      padding: 8px;
      font-size: 14px;
  }
}

.flex-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#page-wrap {
  flex: 1;
  padding-bottom: 10vh; /* Adjust based on footer height */
}

#outer-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}