/* General container styling */
.league-detail-container {
    max-width: 99%;
    margin: 0.1em;
    padding: 0.1em;
    border-radius: 0.3em;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    background-color: #1a1a2e;
    color: #d1d1d1;
}

/* Header styling */
.league-detail-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.25em;
}

.league-detail-header h2 {
    margin: 0 0 0.5em 0;
    font-size: 1.5em;
    color: #d1d1d1;
    text-align: center;
}

.header-buttons {
    display: flex;
    justify-content: center;
    gap: 0.5em;
    width: 100%;
}

/* Buttons styling */
.league-detail-buttons {
    display: flex;
    justify-content: space-between;
    gap: 0.625em;
    margin-bottom: 1.25em;
}

.league-detail-buttons button,
.share-button,
.share-bets-button {
    padding: 0.625em 1em;
    font-size: 0.9em;
    border: none;
    border-radius: 2em;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.league-detail-buttons button:hover,
.share-button:hover,
.share-bets-button:hover {
    background-color: #45a049;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.league-detail-buttons button.dashboard {
    background-color: #008CBA;
}

.league-detail-buttons button.dashboard:hover {
    background-color: #007bb5;
}

.share-button,
.share-bets-button {
    background-color: #3498db;
    flex: 1;
    max-width: 45%;
}

.share-button:hover,
.share-bets-button:hover {
    background-color: #2980b9;
}

/* Bets list styling */
.bets-list {
    list-style-type: none;
    padding: 0;
}

.bet-item {
    display: flex;
    flex-direction: column;
    padding: 1em;
    border: 1px solid #2c2c3e;
    border-radius: 0.5em;
    margin-bottom: 1em;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #23233f;
}

.bet-item:hover {
    background-color: #2c2c4e;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    transform: translateY(-2px);
}

.bet-status {
    align-self: flex-start;
    margin-bottom: 0.5em;
}

.bet-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5em;
}

.bet-creator {
    font-weight: bold;
    color: #4CAF50;
}

.bet-players-info {
    display: flex;
    align-items: center;
    font-size: 0.8em;
    color: #b8b7ad;
}

.user-logo {
    width: 1em;
    height: 1em;
    fill: #4CAF50;
    margin-right: 0.3em;
}

.bet-details {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5em;
    width: 100%;
}

.bet-details p {
    margin: 0;
    font-size: 0.9em;
    color: #d1d1d1;
}

.bet-details p strong {
    color: #b8b7ad;
    margin-right: 0.3em;
}

.status-tag {
    font-size: 0.8em;
    padding: 0.2em 0.5em;
    border-radius: 1em;
    font-weight: bold;
    text-transform: uppercase;
}

.status-tag.validated {
    background-color: #4CAF50;
    color: white;
}

.status-tag.pending {
    background-color: #FFA500;
    color: black;
}

.status-tag.open {
    background-color: #3498db;
    color: white;
}

/* Loading spinner */
.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1a1a2e;
}

.loading-spinner {
    border: 0.25em solid #f3f3f3;
    border-top: 0.25em solid #3498db;
    border-radius: 50%;
    width: 3.125em;
    height: 3.125em;
    animation: spin 1s linear infinite;
}

.loading-container p {
    margin-top: 1.25em;
    font-size: 1.125em;
    color: #d1d1d1;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Load more button */
.load-bets-button,
.load-more-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 0.625em 1.25em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    margin: 0.25em 0.125em;
    cursor: pointer;
    border-radius: 2em;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.load-bets-button:hover,
.load-more-button:hover {
    background-color: #45a049;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

/* Share popup */
.share-popup,
.share-bets-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #23233f;
    padding: 2em;
    border-radius: 1em;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    max-width: 90%;
    width: 400px;
}

.share-popup h3,
.share-bets-modal h3 {
    color: #d1d1d1;
    margin-top: 0;
    margin-bottom: 1em;
    text-align: center;
}

.share-popup button,
.share-bets-modal button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 0.8em 1.5em;
    text-align: center;
    text-decoration: none;
    display: block;
    font-size: 1em;
    margin: 1em 0;
    cursor: pointer;
    border-radius: 2em;
    width: 100%;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.share-popup button:hover,
.share-bets-modal button:hover {
    background-color: #45a049;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.copy-status {
    color: #4CAF50;
    text-align: center;
    margin-top: 1em;
    font-weight: bold;
}

.no-ongoing-bets {
    text-align: center;
    padding: 2em;
    background-color: #23233f;
    border-radius: 0.5em;
    margin-bottom: 1.5em;
}

.no-ongoing-bets p {
    color: #d1d1d1;
    font-size: 1.2em;
    margin: 0;
}

/* Share bets modal specific styles */
.share-bets-modal {
    width: 90%;
    max-width: 500px;
}

.share-bets-list {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 1em;
    padding-right: 1em;
}

.share-bet-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.8em;
}

.share-bet-item label {
    display: flex;
    align-items: center;
    color: #d1d1d1;
    font-size: 0.9em;
    width: 100%;
}

.share-bet-item input[type="checkbox"] {
    margin-right: 0.8em;
    cursor: pointer;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
}

.share-bet-item-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .league-detail-container {
        padding: 1em;
    }

    .league-detail-buttons {
        flex-direction: column;
    }

    .league-detail-buttons button,
    .share-button,
    .share-bets-button {
        width: 100%;
        margin-bottom: 0.5em;
    }

    .bet-details {
        grid-template-columns: 1fr;
    }

    .bet-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .bet-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .bet-players-info {
        margin-top: 0.5em;
    }

    .header-buttons {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .share-button,
    .share-bets-button {
        flex: 1 0 auto;
    }
}

/* Filter button styling */
.filter-button {
    display: flex;
    align-items: center;
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 0.625em 1em;
    cursor: pointer;
    border-radius: 2em;
    font-size: 0.9em;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.filter-button:hover {
    background-color: #45a049;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.filter-icon {
    margin-right: 0.5em;
}

/* Filter popup styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.filter-popup {
    background-color: #23233f;
    padding: 2em;
    border-radius: 1em;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    max-width: 90%;
    width: 400px;
    color: #d1d1d1;
}

.filter-popup h3 {
    color: #d1d1d1;
    margin-top: 0;
    margin-bottom: 1em;
    text-align: center;
}

.filter-popup label {
    display: block;
    margin: 1em 0;
    cursor: pointer;
}

.filter-popup input[type="radio"] {
    margin-right: 0.8em;
    cursor: pointer;
}

.filter-popup button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 0.8em 1.5em;
    text-align: center;
    display: block;
    font-size: 1em;
    margin: 1em 0 0;
    cursor: pointer;
    border-radius: 2em;
    width: 100%;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.filter-popup button:hover {
    background-color: #45a049;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .filter-button {
        width: 100%;
        justify-content: center;
        margin-top: 0.5em;
    }

    .filter-popup {
        width: 90%;
        padding: 1.5em;
    }
}

.league-detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25em;
    padding: 0 1em;
}

.share-icon-button {
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.share-icon-button:hover {
    background-color: #2980b9;
    transform: scale(1.1);
}