/* LoginPage.css */
.login-container {
    max-width: 99%;
    margin: 3em auto;
    padding: 1.25em;
    background-color: #373a47 !important;
    border-radius: 0.625em;
    box-shadow: 0 0 0.625em rgba(0, 0, 0, 0.3);
    color: #d1d1d1;
}

.login-container h1 {
    font-size: 1.5em;
    color: #4CAF50;
    text-align: center;
    margin-bottom: 1em;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 1.25em;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 0.3125em;
    font-weight: bold;
    font-size: 1em;
    color: #b8b7ad;
}

.form-group input {
    width: 100%;
    padding: 0.625em;
    font-size: 1em;
    border: 0.0625em solid #4CAF50;
    border-radius: 0.3125em;
    background-color: #282c34;
    color: #282c34 !important;
    box-sizing: border-box;
}

.form-group input:focus {
    outline: none;
    border-color: #45a049;
    box-shadow: 0 0 0 0.125em rgba(69, 160, 73, 0.2);
}

.login-button,
.sign-up-button,
.forgot-password-button {
    padding: 0.75em 1.25em;
    font-size: 1em;
    color: white;
    background-color: #4CAF50 !important;
    border: none;
    border-radius: 0.3125em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0.3125em 0;
}

.login-button:hover,
.sign-up-button:hover,
.forgot-password-button:hover {
    background-color: #45a049;
}

.login-button:focus,
.sign-up-button:focus,
.forgot-password-button:focus {
    outline: none;
    box-shadow: 0 0 0 0.125em rgba(69, 160, 73, 0.5);
}

.error-message {
    color: #ff4d4d;
    font-size: 0.9em;
    margin-bottom: 0.625em;
    text-align: center;
    background-color: rgba(255, 77, 77, 0.1) !important;
    padding: 0.625em;
    border-radius: 0.3125em;
}

/* Disabled states */
.login-button:disabled,
.sign-up-button:disabled,
.forgot-password-button:disabled {
    background-color: #6b6b6b !important;
    cursor: not-allowed;
    opacity: 0.7;
}

.form-group input:disabled {
    background-color: #3a3f4b;
    border-color: #6b6b6b;
    cursor: not-allowed;
}

/* Loading state */
.login-button.loading {
    position: relative;
    padding-right: 2.5em;
}

/* Media Queries */
@media (max-width: 37.5em) {
    .login-container {
        margin: 1.5em auto;
        padding: 1em;
    }

    .form-group input {
        padding: 0.75em;
    }

    .login-button,
    .sign-up-button,
    .forgot-password-button {
        padding: 0.875em 1.25em;
        font-size: 1.1em;
    }
}

/* Optional: Add styles for placeholder text */
.form-group input::placeholder {
    color: #6b6b6b;
}

/* Optional: Add styles for autofill */
.form-group input:-webkit-autofill,
.form-group input:-webkit-autofill:hover,
.form-group input:-webkit-autofill:focus {
    -webkit-text-fill-color: #d1d1d1;
    -webkit-box-shadow: 0 0 0px 1000px #282c34 inset;
    transition: background-color 5000s ease-in-out 0s;
}