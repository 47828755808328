/* Page layout and refresh button */
.league-outcome-page {
    position: relative;
    padding-top: 80px;
}

/* New container for the refresh button */
.refresh-button-container {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
    background-color: #1a1a2e;
    margin-bottom: 20px;
}

/* Updated refresh button styles */
.refresh-button {
    background-color: #4CAF50;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.refresh-button:hover {
    background-color: #45a049;
    transform: scale(1.1);
}

.refresh-button svg {
    width: 20px;
    height: 20px;
    color: #ffffff;
    transition: transform 0.3s ease;
}

.refresh-button:hover svg {
    transform: rotate(180deg);
}

/* General container styling */
.league-outcome-container {
    position: relative;
    max-width: calc(99%);
    margin-left: auto;
    margin-right: auto;
    padding: 2em;
    border-radius: 0.625em;
    box-shadow: 0 0 0.625em rgba(0, 0, 0, 0.3);
    background-color: #1a1a2e;
    color: #d1d1d1;
}

/* Header styling */
.league-outcome-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.25em;
}

.league-outcome-header h1 {
    margin: 0 0 0.5em 0;
    font-size: 1.5em;
    color: #d1d1d1;
    text-align: center;
}

.league-outcome-header h2 {
    margin: 0.3125em 0;
    font-size: 1.125em;
    color: #b8b7ad;
}

/* Button Group Styling */
.button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

/* General Button Styling */
.action-button {
    flex: 1 1 auto;
    min-width: 120px;
    padding: 10px 15px;
    font-size: 14px;
    text-align: center;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    color: #ffffff;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Back to League Button */
.back-league-button {
    background-color: #3498db;
}

.back-league-button:hover {
    background-color: #2980b9;
}

/* Toggle View Button */
.toggle-view-button {
    background-color: #e74c3c;
}

.toggle-view-button:hover {
    background-color: #c0392b;
}

/* Status circle styling */
.status-circle {
    display: inline-block;
    width: 0.75em;
    height: 0.75em;
    border-radius: 50%;
    margin-right: 0.625em;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}

/* Bets sections styling */
.bets-sections {
    display: flex;
    flex-direction: column;
    gap: 1.25em;
}

.bet-section {
    border: 1px solid #2c2c3e;
    border-radius: 0.3125em;
    padding: 0.9375em;
}

.bet-section h3 {
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0.9375em;
    color: #d1d1d1;
    font-size: 1.125em;
}

/* Bet card styling */
.bet-card {
    display: flex;
    align-items: flex-start;
    padding: 0.9375em;
    border-radius: 0.5em;
    background-color: #23233f;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin-bottom: 0.625em;
    cursor: pointer;
}

.bet-card:hover {
    background-color: #2c2c4e;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
}

.bet-card .status-circle {
    margin-top: 0.1875em;
}

.bet-content {
    flex-grow: 1;
    margin-left: 0.9375em;
}

.bet-detail,
.bet-participants {
    margin-bottom: 0.625em;
}

.bet-card p {
    margin: 0.3125em 0;
    font-size: 0.875em;
    color: #d1d1d1;
}

.bet-card p strong {
    color: #b8b7ad;
}

/* Summary styling */
.summary {
    margin-bottom: 1.25em;
}

.summary h3 {
    color: #d1d1d1;
    margin-bottom: 0.625em;
}

.score-card {
    background-color: #23233f;
    padding: 0.625em;
    border-radius: 0.3125em;
    margin-bottom: 0.3125em;
}

.score-card p {
    margin: 0;
    color: #d1d1d1;
}

/* Loading, error, and no-data states */
.loading, .error, .no-data {
    text-align: center;
    padding: 1.25em;
    font-size: 1.125em;
    color: #d1d1d1;
}

.error {
    color: #ff6b6b;
}

/* Table view styling */
.table-view {
    overflow-x: auto;
}

.table-view table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1.25em;
}

.table-view th, .table-view td {
    border: 1px solid #2c2c3e;
    padding: 0.5em;
    text-align: left;
}

.table-view th {
    background-color: #2c2c4e;
    font-weight: bold;
    color: #d1d1d1;
}

.table-view .total-row {
    background-color: #23233f;
}

.table-view .total-row td {
    font-weight: bold;
}

.load-previous-button {
    margin-bottom: 1.25em;
    padding: 0.625em 1.25em;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 2em;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.load-previous-button:hover {
    background-color: #45a049;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.monthly-total-row {
    background-color: #2c2c4e;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .league-outcome-container {
        padding: 1em;
    }

    .button-group {
        flex-direction: column;
    }

    .action-button {
        width: 100%;
    }

    .bet-card {
        flex-direction: column;
    }

    .bet-card .status-circle {
        margin-bottom: 0.625em;
    }

    .bet-content {
        margin-left: 0;
    }

    .refresh-button-container {
        padding: 10px;
    }

    .refresh-button {
        width: 36px;
        height: 36px;
    }

    .refresh-button svg {
        width: 18px;
        height: 18px;
    }
}

