/* Sidebar.css */

.bm-menu-wrap {
    position: fixed;
    height: 100%;
    left: 0;
    z-index: 1100; /* Increase this value */
}

.bm-menu {
    background: #1a1a2e;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    overflow-y: auto; /* Ensure scrolling if content exceeds viewport */
}

.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

.bm-item {
    display: block; /* Ensure full width */
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
    width: 100%; /* Full width for buttons */
    text-align: center; /* Center text */
}

.bm-item button {
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: 100%; /* Full width */
}

.bm-item:hover {
    color: white;
}

.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    transition: background 0.3s ease;
    z-index: 1000; /* Smooth transition */
}

.error-message {
    color: red;
    text-align: center; /* Center error messages */
    margin-top: 10px;
}
