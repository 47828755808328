:root {
    --primary-bg: #1a1a2e;
    --secondary-bg: #16213e;
    --accent-color: #4ecca3;
    --text-primary: #e1e1e1;
    --text-secondary: #b8b7ad;
    --card-bg: #23233f;
    --success-color: #4CAF50;
    --warning-color: #ff9800;
    --error-color: #f44336;
}

.main-page-container {
    max-width: 99%;
    margin: 0.1em;
    padding: 0.1em;
    background-color: var(--primary-bg);
    color: var(--text-primary);
}

.main-page-header {
    text-align: center;
    padding: 20px;
    margin-bottom: 30px;
}

.main-page-header h1 {
    color: var(--accent-color);
    font-size: 2em;
    margin: 0;
}

.leagues-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
}

.league-card {
    background-color: var(--secondary-bg);
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.league-card:hover {
    transform: translateY(-5px);
}

.league-header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--card-bg);
    cursor: pointer;
}

.league-header-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.go-to-league-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.go-to-league-button:hover {
    background-color: #45a049;
    transform: translateY(-1px);
}

.button-icon {
    font-size: 0.9rem;
}

/* Ensure the button doesn't get hidden when the card is collapsed */
.league-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}
.league-header h2 {
    margin: 0;
    color: var(--accent-color);
}

.league-expand-icon {
    transition: transform 0.3s ease;
}

.league-expand-icon.expanded {
    transform: rotate(180deg);
}

.league-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.league-content.expanded {
    max-height: 2000px;
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 15px;
    padding: 20px;
}

.stat-card {
    background-color: var(--card-bg);
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.stat-icon {
    font-size: 24px;
    color: var(--accent-color);
}

.stat-value {
    font-size: 24px;
    font-weight: bold;
    color: var(--text-primary);
}

.stat-subvalue {
    font-size: 0.8em;
    color: var(--text-secondary);
    margin-top: 0.2em;
}

.stat-icon.success {
    color: var(--success-color);
}

.stat-icon.danger {
    color: var(--error-color);
}
.stat-label {
    font-size: 14px;
    color: var(--text-secondary);
}

.trend-indicator {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
}

.trend-up {
    color: var(--success-color);
}

.trend-down {
    color: var(--error-color);
}

.trend-neutral {
    color: var(--warning-color);
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.error-message {
    color: var(--error-color);
    text-align: center;
    padding: 20px;
}

@media (max-width: 768px) {
    .leagues-grid {
        grid-template-columns: 1fr;
    }

    .stats-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}