/* General Container */
.dashboard-container {
    max-width: 99%;
    margin: 0.1em;
    padding: 0.1em;
    font-family: Arial, sans-serif;
    background-color: #1a1a2e; /* Primary background color */
    color: #d1d1d1 !important; /* Primary text color */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* Header */
.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* Message styling */
.message {
    color: #ff4d4d; /* Error text color */
    margin-bottom: 20px;
    font-size: 16px;
    text-align: center; /* Center error messages */
}

/* Dashboard buttons */
.dashboard-buttons {
    display: flex;
    flex-direction: column; /* Stack buttons vertically on mobile */
    gap: 10px;
    margin-bottom: 20px;
}

.dashboard-buttons button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #4CAF50; /* Primary button background color */
    color: white !important;
    transition: background-color 0.3s ease;
}

.dashboard-buttons button:hover {
    background-color: #45a049; /* Button hover color */
}

/* List of leagues */
.leagues-list {
    list-style-type: none;
    padding: 0;
}

/* Individual league item */
.league-item {
    background-color: #282c34; /* Secondary background color */
    padding: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.league-item:hover {
    background-color: #333842;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* League item text */
.league-item h3 {
    margin: 0;
    font-size: 18px;
    color: #d1d1d1; /* Primary text color */
}

.league-item p {
    margin: 0;
    font-size: 14px;
    color: #b8b7ad; /* Secondary text color */
}

/* Modal content */
/* Modal content */
.modal-content {
    padding: 5%;
    background: #373a47; /* Primary background color */
    border-radius: 5px;
    width: 90%;
    max-width: 500px;
    max-height: 90vh; /* Maximum 90% of viewport height */
    margin: 5vh auto; /* Center vertically and horizontally */
    color: #d1d1d1 !important; /* Primary text color */
    overflow-y: auto; /* Allow scrolling if content is too long */
}

.modal-content h2 {
    margin-top: 0;
    font-size: 1.5em;
    color: #d1d1d1 !important; /* Primary text color */
    margin-bottom: 0.5em;
}

.modal-content label {
    display: block;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.modal-content input {
    width: 100%;
    padding: 10px;
    margin: 5px 0 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 16px;
    background-color: #282c34; /* Secondary background color */
    color: #d1d1d1 !important; /* Primary text color */
    box-sizing: border-box; /* Include padding in width calculation */
}

/* Modal buttons */
.modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.modal-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    width: 48%; /* Allow some space between buttons */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .modal-content {
        width: 95%;
        padding: 5%;
        margin: 2.5vh auto;
    }

    .modal-buttons {
        flex-direction: column;
    }

    .modal-buttons button {
        width: 100%;
        margin-bottom: 10px;
    }
}

/* Create button */
.create-button {
    background-color: #4CAF50; /* Primary button background color */
    color: white;
}

.create-button:hover {
    background-color: #45a049; /* Button hover color */
}

/* Cancel button */
.cancel-button {
    background-color: #e74c3c; /* Red */
    color: white;
}

.cancel-button:hover {
    background-color: #c0392b;
}

/* Join button */
.join-button {
    background-color: #4CAF50; /* Primary button background color */
    color: white;
}

.join-button:hover {
    background-color: #45a049; /* Button hover color */
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .dashboard-container {
        padding: 15px;
    }

    .dashboard-buttons {
        flex-direction: column;
        gap: 10px;
    }

    .modal-buttons {
        flex-direction: column;
        gap: 10px;
    }
}
