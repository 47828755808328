.forgot-password-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #333;
}

.forgot-password-form .form-group {
    margin-bottom: 15px;
}

.forgot-password-form label {
    display: block;
    margin-bottom: 5px;
    color: white !important;
    font-weight: bold;
}

.forgot-password-form input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.send-code-button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50 !important;;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.error-message {
    color: red;
}

.success-message {
    color: green;
}
