:root {
    --primary-bg: #1a1a2e;
    --secondary-bg: #16213e;
    --accent-color: #4ecca3 ;
    --text-primary: #e1e1e1 !important;
    --text-secondary: #e1e1e1 !important;
    --error-color: #e74c3c;
    --success-color: #2ecc71;
  }
  
  .bet-category {
    margin-bottom: 20px;
  }
  
  .bet-category h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .bets-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  

  body {
    background-color: var(--primary-bg);
    color: var(--text-primary) !important;
    font-family: 'Arial', sans-serif;
  }
  
  .bet-detail-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .bet-detail-card {
    background-color: var(--secondary-bg);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .bet-title {
    color: var(--accent-color);
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .bet-info p {
    margin: 10px 0;
    display: flex;
    align-items: center;
  }
  
  .bet-info p span[class^="icon-"] {
    margin-right: 10px;
    color: var(--accent-color);
  }
  
  .participants-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .participants-card {
    flex-basis: calc(50% - 10px);
    background-color: var(--secondary-bg);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .participants-card h3 {
    color: var(--accent-color);
    font-size: 18px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }
  
  .participants-list {
    list-style-type: none;
    padding: 0;
  }
  
  .participants-list li {
    background-color: var(--primary-bg);
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .outcome-info {
    font-size: 0.9em;
    margin-top: 5px;
    display: block;
  }
  
  .outcome-info.validated {
    color: var(--success-color);
  }
  
  .outcome-info.challenged {
    color: var(--error-color);
  }
  
  button {
    background-color: var(--accent-color);
    color: var(--primary-bg);
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #45b393;
  }
  
  button:disabled {
    background-color: var(--text-secondary);
    cursor: not-allowed;
  }
  
  .btn-challenge, .btn-cancel {
    background-color: var(--error-color);
  }
  
  .btn-challenge:hover, .btn-cancel:hover {
    background-color: #c0392b;
  }
  
  input[type="number"] {
    padding: 10px;
    border: 1px solid var(--accent-color);
    border-radius: 4px;
    background-color: var(--primary-bg);
    color: var(--text-primary);
    font-size: 16px;
  }
  
  .modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: var(--secondary-bg);
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;
    width: 90%;
  }
  
  .modal h3 {
    color: var(--accent-color);
    margin-bottom: 20px;
  }
  
  .outcome-selection {
    margin-bottom: 20px;
  }
  
  .outcome-selection label {
    display: block;
    margin-bottom: 10px;
  }
  
  .deadline-message, .status-message {
    background-color: var(--secondary-bg);
    color: var(--text-primary);
    padding: 10px;
    border-radius: 4px;
    margin: 10px 0;
    font-weight: bold;
  }
  
  .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .no-participants {
    color: var(--text-secondary);
    font-style: italic;
  }
  
  @media (max-width: 768px) {
    .participants-container {
      flex-direction: column;
    }
  
    .participants-card {
      flex-basis: 100%;
    }
  
    .navigation-buttons {
      flex-direction: column;
    }
  
    .navigation-buttons button {
      margin-bottom: 10px;
    }
  }

  .status-message, .submitter-waiting p {
    color: #ffffff; /* Bright white for high contrast */
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent dark background */
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: center;
}

/* If you want to add a subtle border for extra visibility */
.status-message, .submitter-waiting p {
    border: 1px solid #4ecca3; /* Using the accent color for the border */
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.loading-container p {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}