/* UnseenBets.css */
.unseen-bets-container {
    max-width: 99%;
    margin: 0.1em;
    padding: 0.1em;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: #373a47;
    color: #d1d1d1;
}

.unseen-bets-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.unseen-bets-header h1 {
    margin: 0;
    font-size: 24px;
    color: #d1d1d1;
}

.button-group {
    display: flex;
    gap: 10px;
}

.refresh-button,
.mark-all-seen-button,
.load-more-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    transition: background-color 0.3s ease;
}

.refresh-button:hover,
.mark-all-seen-button:hover,
.load-more-button:hover {
    background-color: #45a049;
}

.card {
    margin-bottom: 30px;
}

.card h2 {
    color: #b8b7ad;
    font-size: 20px;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #4a4a4a;
}

.bet-card,
.close-request-card {
    display: flex;
    align-items: stretch;
    background-color: #282c34;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    margin-bottom: 15px;
}

.bet-card:hover,
.close-request-card:hover {
    background-color: #333842;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.bet-urgency {
    width: 10px;
    transition: background-color 0.3s ease;
}

.bet-content {
    flex-grow: 1;
    padding: 15px;
}

.bet-content h3 {
    margin: 0 0 10px 0;
    font-size: 18px;
    color: #ffffff;
}

.bet-content p {
    margin: 5px 0;
    font-size: 14px;
    color: #d1d1d1;
}

.bet-content p strong {
    color: #f5f5f5;
}

.loading,
.error,
.no-bets,
.no-requests {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: #d1d1d1;
}

.error {
    color: #ff6b6b;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .unseen-bets-container {
        padding: 15px;
    }

    .unseen-bets-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .refresh-button,
    .mark-all-seen-button,
    .load-more-button {
        width: 100%;
    }

    .bet-card,
    .close-request-card {
        flex-direction: column;
    }

    .bet-urgency {
        height: 5px;
        width: 100%;
    }
}