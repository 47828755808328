:root {
    --primary-bg: #1a1a2e;
    --secondary-bg: #16213e;
    --accent-color: #4ecca3;
    --text-primary: #e1e1e1;
    --text-secondary: #e1e1e1;
    --error-color: #e74c3c;
    --success-color: #2ecc71;
}

.tombola-bet-container {
    max-width: 99%;
    margin: 0.1em;
    padding: 0.1em;
    background-color: var(--primary-bg);
    color: var(--text-primary);
    font-family: 'Arial', sans-serif;
}

.tombola-bet-container h1 {
    color: var(--accent-color);
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.bet-info {
    background-color: var(--secondary-bg);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.bet-info p {
    margin: 10px 0;
    display: flex;
    align-items: center;
}

.bet-info p strong {
    margin-right: 10px;
    color: var(--accent-color);
}

.outcomes-section {
    background-color: var(--secondary-bg);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.outcomes-section h2 {
    color: var(--accent-color);
    font-size: 18px;
    margin-bottom: 15px;
}

.outcomes-section ul {
    list-style-type: none;
    padding: 0;
}

.outcomes-section li {
    background-color: var(--primary-bg);
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
}

.join-bet-section {
    background-color: var(--secondary-bg);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.join-bet-section h2 {
    color: var(--accent-color);
    font-size: 18px;
    margin-bottom: 15px;
}

.join-bet-section input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--accent-color);
    border-radius: 4px;
    background-color: var(--primary-bg);
    color: var(--text-primary);
    font-size: 16px;
    margin-bottom: 10px;
}

button {
    background-color: var(--accent-color);
    color: var(--primary-bg);
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #45b393;
}

button:disabled {
    background-color: var(--text-secondary);
    cursor: not-allowed;
}

.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--primary-bg);
}

.loading-spinner {
    border: 4px solid var(--secondary-bg);
    border-top: 4px solid var(--accent-color);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

.loading-container p {
    margin-top: 20px;
    font-size: 18px;
    color: var(--text-primary);
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
    .tombola-bet-container {
        padding: 10px;
    }

    button {
        width: 100%;
        margin-bottom: 10px;
    }
}


/* Add this to your existing CSS */

.ReactModal__Overlay {
    background-color: rgba(26, 26, 46, 0.75) !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ReactModal__Content {
    background-color: var(--secondary-bg) !important;
    border: 1px solid var(--accent-color) !important;
    border-radius: 8px !important;
    color: var(--text-primary) !important;
    max-width: 90% !important;
    width: 350px !important;
    padding: 20px !important;
    position: relative !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    margin: 10px !important;
    overflow: auto !important;
}

.ReactModal__Content h2 {
    color: var(--accent-color);
    font-size: 20px;
    margin-bottom: 15px;
    text-align: center;
}

.ReactModal__Content select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    background-color: var(--primary-bg);
    color: var(--text-primary);
    border: 1px solid var(--accent-color);
    border-radius: 4px;
    font-size: 16px;
}

.ReactModal__Content button {
    width: 100%;
    margin-bottom: 10px;
}

.ReactModal__Content button:last-child {
    background-color: var(--error-color);
}

.ReactModal__Content button:last-child:hover {
    background-color: #c0392b;
}

@media (max-width: 768px) {
    .ReactModal__Content {
        width: 90% !important;
        padding: 15px !important;
    }
}

.ReactModal__Overlay {
    background-color: rgba(26, 26, 46, 0.75) !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    z-index: 1000 !important;
}

.ReactModal__Content {
    background-color: var(--secondary-bg) !important;
    border: 1px solid var(--accent-color) !important;
    border-radius: 8px !important;
    color: var(--text-primary) !important;
    max-width: 90% !important;
    width: 350px !important;
    padding: 20px !important;
    position: relative !important;
    margin: auto !important;
    overflow: auto !important;
    outline: none !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}
.user-action {
    font-size: 0.8em;
    margin-left: 10px;
}

.user-action.close {
    color: #4CAF50;
}

.user-action.validate {
    color: #4CAF50;
}

.user-action.challenge {
    color: #F44336;
}