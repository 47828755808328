.league-stats-container {
    background-color: #1a1a2e;
    color: #d1d1d1;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.league-stats-header {
    margin-bottom: 20px;
}

.league-stats-header button {
    width: 100%;
    padding: 12px;
    margin-bottom: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.league-stats-header h1 {
    text-align: center;
    font-size: 20px;
    margin: 15px 0;
}

.stat-leaderboard-card {
    background-color: #23233f;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    width: 100%;
    box-sizing: border-box;
}

.card-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.stat-icon {
    color: #4CAF50;
}

.card-header h3 {
    margin: 0;
    font-size: 16px;
}

.leaderboard-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #2c2c4e;
    border-radius: 5px;
    margin-bottom: 8px;
}

.rank {
    color: #4CAF50;
    margin-right: 10px;
    min-width: 20px;
}

.username {
    flex: 1;
    margin: 0 10px;
}

.value {
    color: #4CAF50;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.loading-spinner {
    border: 4px solid #2c2c4e;
    border-top: 4px solid #4CAF50;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

.error-message {
    color: #ff6b6b;
    text-align: center;
    padding: 20px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}